
import React, { useState, useEffect, useLayoutEffect } from "react";
import { BrowserRouter as  Router, Route, Routes,  } from "react-router-dom";

import Login            from '../Login/Login';
import PasswordReset    from '../Resetpassword/';
import Dashboard        from '../Dashboard/Dashboard';
import SchoolBlog       from '../Dashboard/SchoolBlog';
import StudentSubject   from '../Dashboard/StudentSubject';
import GeneralCbt       from '../Dashboard/GeneralCbt';
import Profile          from '../Dashboard/Profile';
import CheckExamResult  from '../Dashboard/CheckExamResult';
import Logout           from "../Dashboard/pages/Logout";
import ReturnToPage     from "../Dashboard/CBT/ReturnToPage";
 
import { MdTipsAndUpdates } from "react-icons/md";
import SideBar          from '../Sidebar/SideBar'; 

import Loading3 from '../loaders/Loading3';
import Loading2 from '../loaders/Loading2';
import Loading from '../loaders/Loading';
import SchoolLoading from '../loaders/SchoolLoading';


import { AuthContext }     from '../context'; 
import { AccountUpdate,BasePageAction } from '../../BaseUrl';
import Modal               from "../UI/Modal/Modal";   

function Home() {

  const[loginData, setLoginData] = useState([]); 
  const[schoolBgcolor,  setSchoolBgcolor] = useState(''); 
  const[school_code,  setSchoolcode] = useState(''); 
  const[schoolLogo,  setSchoolLogo] = useState(''); 
  const[schoolName,  setSchoolName] = useState(''); 
  const[schoolMotor, setSchoolMotor] = useState('');  
  const[schoolPhone, setSchoolPhone]   = useState(''); 
  const[schoolAddress, setSchoolAddress]   = useState(''); 
  const[schoolEmail, setSchoolEmail]   = useState('');  
  const[message, setMessage] = useState('');
  const[activateLoader, setActivateLoader]   = useState(true); 
  const[schoolScreen,  setSchoolScreen] = useState('');
  const[isLoading2, setIsLoading2]   = useState(false);
  const[isLoading3, setIsLoading3]   = useState(false); 
  const[cbtSwitchTab, setCbtSwitchTab]   = useState(false); 

  const[show, setShow] = useState(false);
  const[loader, setLoader] = useState(false);  

  const[userToken, setUserToken]   = useState('0'); 

  const authContext = React.useMemo(() => ({
  
    
  
  signIn : () => {
    setShow(true);
    
      //window.location.reload();
      

      const data1 = localStorage.getItem('school_bgcolor');
      const data2 = localStorage.getItem('school_logo');
      const data3 = localStorage.getItem('school_name');
      const data4 = localStorage.getItem('school_motor');

      setSchoolBgcolor(data1);
      setSchoolLogo(data2);
      setSchoolName(data3);
      setSchoolMotor(data4);

      setUserToken("1"); 
      setShow(false); 
       // alert('WELCOME! Account Logged In Successfully');
     
  },

  signOut: () => {


    setShow(true);
 
    localStorage.removeItem('reg_status');  
    localStorage.removeItem('login_session');
    localStorage.removeItem('school_bgcolor');
    localStorage.removeItem('authLogin');
    localStorage.removeItem('schoolCode');
    localStorage.removeItem('parentCode');
    localStorage.removeItem('school_logo');
    localStorage.removeItem('school_name');
    localStorage.removeItem('school_motor'); 
    localStorage.removeItem('school_address'); 
    localStorage.removeItem('school_phone'); 
    localStorage.removeItem('school_email'); 
    localStorage.removeItem('text_code'); 
    localStorage.removeItem('studentOnListData'); 
    localStorage.removeItem('studentSubjectData'); 
    localStorage.removeItem('onlineStuIdData'); 
    localStorage.removeItem('studentCurrentExam'); 
    localStorage.removeItem('token'); 
    localStorage.removeItem('online_stu_id'); 
    setCbtSwitchTab(false); 
    setUserToken("0");
  
    setShow(false);
 
        
  

  },

  endCBT: () => {
    
    localStorage.removeItem('exam_time'); 
    localStorage.removeItem('test_time'); 
    localStorage.removeItem('cbtOn'); 
    localStorage.removeItem('subject'); 
    localStorage.removeItem('studentCurrentExam'); 
    setCbtSwitchTab(false);  
  },
  
  startCBT: () => {
    localStorage.setItem('cbtOn', '1');
    setCbtSwitchTab(true);  
 
  },
  

  }));

 
const autoUserDataReload = () => {
  setLoader(true)

  const token = localStorage.getItem('token');
  const online_stu_id = localStorage.getItem('online_stu_id');
      
  var headers = {
  'Accept': 'application/json',
  'Content-Type': 'application/json'
  }

  fetch(BasePageAction+"fingerPrintLogin&token="+token+"&stuID="+online_stu_id,
  {
  method:'GET',
  header:headers
  })
  .then((response) => response.json())
  .then((response) => 
  {

    
          if(response[0].success == 'ok')
          {
          setLoader(false)


          var obj = {
            
            admincode            : response[0].admincode,
            marketer_code        : response[0].marketer_code,
            fadmin_code          : response[0].fadmin_code,
            school_code          : response[0].school_code,
            school_name          : response[0].school_name,
            school_photo         : response[0].school_photo,
            school_logo          : response[0].school_logo,
            school_email         : response[0].school_email,
            school_password      : response[0].school_password,
            school_phone         : response[0].school_phone,
            school_address       : response[0].school_address,
            school_motor         : response[0].school_motor,
            school_bgcolor       : response[0].school_bgcolor,
            text_code            : response[0].text_code,
            school_week          : response[0].school_week,
            last_pay_date        : response[0].last_pay_date,
            bank_name            : response[0].bank_name,
            account_name         : response[0].account_name,
            account_number       : response[0].account_number,
            school_status        : response[0].school_status,
            schl_propritor_name  : response[0].schl_propritor_name,
            schl_propritor_photo : response[0].schl_propritor_photo,
            schl_propritor_msg   : response[0].schl_propritor_msg,
            schl_head_name       : response[0].schl_head_name,
            schl_head_photo      : response[0].schl_head_photo,
            schl_head_msg        : response[0].schl_head_msg,
            date_reg             : response[0].date_reg,
            exam_score           : response[0].exam_score,
            exam_time            : response[0].exam_time,
            test_score           : response[0].test_score,
            test_time            : response[0].test_time,
            school_website       : response[0].school_website,
                                                
            //////////////////////////////////PARENT BLOCK /////////////////////////////

            admin_code           : response[0].admin_code,
            sch_code             : response[0].sch_code,
            username             : response[0].username,
            parent_code          : response[0].parent_code,
            guidance_name        : response[0].guidance_name,
            address              : response[0].address,
            email                : response[0].email,  

                //////////////////////////////////STUDENT BLOCK /////////////////////////////                                                    
                            
            admincode           : response[0].admincode,
            photo               : response[0].photo,
            schl_stu_no         : response[0].schl_stu_no,
            online_stu_id       : response[0].online_stu_id,  
            student_name        : response[0].student_name,
            stu_gender          : response[0].stu_gender,
            student_class       : response[0].student_class,
            class_rep           : response[0].class_rep,
            student_teacher     : response[0].student_teacher,
            sub_status          : response[0].sub_status,
            token               : response[0].token,
            date                : response[0].date,     


              };


                
                    localStorage.setItem('reg_status', response[0].sub_status);  
                    localStorage.setItem('login_session',JSON.stringify(obj));
                    localStorage.setItem('school_bgcolor', response[0].school_bgcolor);
                    localStorage.setItem('text_code', response[0].text_code);
                    localStorage.setItem('authLogin', '1');
                    localStorage.setItem('online_stu_id', response[0].online_stu_id);
                    localStorage.setItem('token', response[0].token);
                    localStorage.setItem('schoolCode', response[0].school_code);
                    localStorage.setItem('parentCode', response[0].parent_code);
                    localStorage.setItem('school_logo', response[0].school_logo);
                    localStorage.setItem('school_name', response[0].school_name);
                    localStorage.setItem('school_address', response[0].school_address);
                    localStorage.setItem('school_email', response[0].school_email);
                    localStorage.setItem('school_motor', response[0].school_motor);
                    localStorage.setItem('school_phone', response[0].school_phone);

            }

})
.catch(error => {
  //setMessage('Oops! network error, please check your data and try again');
});

 

}
 
const LoginOptionUpdate =() => 
  {
    const value =  localStorage.getItem('login_session');
    const data = JSON.parse(value);  
    setLoginData(data);
  }   
   
useEffect(() => {

 
    setTimeout(async() => {
    
       autoUserDataReload();  
      LoginOptionUpdate();  
      
  
  }, 60000);
   
  
     
    
    },[loginData]);

useLayoutEffect(() => {

  setTimeout( () => {
  
  
    const cbtOn                =localStorage.getItem('cbtOn');
    const authLogin            =localStorage.getItem('authLogin');
    const data1                =localStorage.getItem('school_bgcolor');
    const data2                =localStorage.getItem('school_logo');
    const data3                =localStorage.getItem('school_name');
    const data4                =localStorage.getItem('school_motor'); 
    const school_address_data  =localStorage.getItem('school_address'); 
    const school_email_data    =localStorage.getItem('school_email'); 
    const school_phone_data    =localStorage.getItem('school_phone'); 
    const school_code          =localStorage.getItem('schoolCode'); 
    
    if(authLogin == 1 )
    {  
  
      setUserToken("1"); 
      setSchoolBgcolor(data1);
      setSchoolLogo(data2);
      setSchoolName(data3);
      setSchoolMotor(data4); 
      setSchoolScreen(true);
      setSchoolAddress(school_address_data);
      setSchoolEmail(school_email_data);
      setSchoolPhone(school_phone_data);
      setSchoolcode(school_code);
    }else{
       setUserToken("0");
    }
  
   if(cbtOn == '1'){
    setCbtSwitchTab(true)
   }else{
    setCbtSwitchTab(false)
   }


  });
  
  
  
  
  }); 
 

useEffect(() => {

    setTimeout(async() => {
    
    
    
      setActivateLoader(false);
     
       
    
    }, 5000);
    
    
    
    
    },[]); 

 
const  ReLoadHanduler =() =>{

      setActivateLoader(false); 
      setIsLoading3(false);
      setIsLoading2(false);
      
     
    }   
  

    if(cbtSwitchTab)
      {
                          return (  
                          <AuthContext.Provider value={authContext}>
                                      
                          <Router>                        
                              <Routes> 
                                   
                                  <Route path='/'                    element={<ReturnToPage   school_code={school_code}   schoolBgcolor={schoolBgcolor}  schoolLogo={schoolLogo}  schoolName={schoolName} schoolMotor={schoolMotor}  schoolPhone={schoolPhone}  schoolAddress={schoolAddress} schoolEmail={schoolEmail}  />}  />   
                                  <Route path='/general-cbt'         element={<GeneralCbt      />} />   
                               
                                      
                                  <Route path='*'                    element={<p> Page not found</p>} />   
                              </Routes>        
                            </Router>

                            </AuthContext.Provider> 
                          );
      }
      else
      {
            if(activateLoader)
                {
                
                
                        if(isLoading2)
                        {
                
                              return(
                                <div> 
                                <Loading2  />
                                </div>
                              )
                              
                        }
                        else if(isLoading3)
                        {
                              return(
                                <div> 
                                <Loading3   ReLoadHanduler={ReLoadHanduler} />
                                </div>
                              )
                
                        }           
                        else  
                        {      
                              if(schoolScreen)
                              {
                                        return(     
                                            <div> 
                                            <SchoolLoading  
                                            
                                            school_code={school_code}
                                            schoolBgcolor={schoolBgcolor}
                                            schoolLogo={schoolLogo}
                                            schoolName={schoolName}
                                            schoolMotor={schoolMotor}
                                            />
                                            </div>
                                            
                                              
                                        )
                              }
                              else
                              {
                                        return( 
                                        <div> 
                                        <Loading  />
                                        </div> 
                                        )       
                              }             
                
                
                        }
                            
                
                
                
                  
                
                
            }
            else
            {

                
                if(userToken == '0'){
                
                      return (  
                        <AuthContext.Provider value={authContext}>
                      
                      <Router>
                  
                                    <Routes>
                                          <Route path='/'        element={<Login                    />} />     
                                          <Route path='/Home'    element={<Login                    />} />     
                                          <Route path='/Login'   element={<Login                    />} />      
                                          <Route path='*'        element={<p> Page not found       </p>}/>   
                                    </Routes>
                        </Router>

                        </AuthContext.Provider>        
                      );
                }
                else
                {   

                    return (  
                        <AuthContext.Provider value={authContext}>
                      
                        <Router>          
                                  <SideBar>
                                                          
                                          <Routes> 
                                              <Route path='/'                         element={<Dashboard      schoolBgcolor={schoolBgcolor}  schoolLogo={schoolLogo}  schoolName={schoolName} schoolMotor={schoolMotor}  schoolPhone={schoolPhone}  schoolAddress={schoolAddress} schoolEmail={schoolEmail}  />}  />   
                                              <Route path='/profile'                  element={<Profile        schoolBgcolor={schoolBgcolor}  schoolLogo={schoolLogo}  schoolName={schoolName} schoolMotor={schoolMotor}  schoolPhone={schoolPhone}  schoolAddress={schoolAddress} schoolEmail={schoolEmail}  />}  />   
                                              <Route path='/school-blog'              element={<SchoolBlog     schoolCode={school_code} schoolBgcolor={schoolBgcolor}  schoolLogo={schoolLogo}  schoolName={schoolName} schoolMotor={schoolMotor}  schoolPhone={schoolPhone}  schoolAddress={schoolAddress} schoolEmail={schoolEmail}  />}  />   
                                              <Route path='/settings/password-reset'  element={<PasswordReset  schoolBgcolor={schoolBgcolor}  schoolLogo={schoolLogo}  schoolName={schoolName} schoolMotor={schoolMotor}  schoolPhone={schoolPhone}  schoolAddress={schoolAddress} schoolEmail={schoolEmail}      />} />   
                                               <Route path='/stu-subject'             element={<StudentSubject         />} />   
                                              <Route path='/general-cbt'              element={<GeneralCbt             />} />     
                                              <Route path='/student-exam-result'      element={<CheckExamResult        />} />   
                                              <Route path='/Logout'                   element={<Logout                 />} />   
                                              <Route path='*'                         element={<p> Page not found    </p>} />   
                                          </Routes> 
                                                    
                                  </SideBar>
                                        
                          </Router>

                          </AuthContext.Provider> 
                        );     
                }
            }
                
      }
      


}

export default Home;



 
 